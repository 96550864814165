<form class="w-full" [formGroup]="formGroup" (ngSubmit)="onSave()">
    <div class="gap-5 grid sm:grid-cols-1 w-full">
        <p-accordion expandIcon="ph ph-caret-double-left" collapseIcon="ph ph-caret-double-up">
            <sms-accordion-tab [selected]="true" header="FATHER'S DETAILS">
                <div class="flex items-start gap-4 flex-col" formGroupName="father">
                    <sms-text-input label="Father's Name" formControlName="name" smsUppercase></sms-text-input>
                    <sms-text-input
                        label="Education Qualification"
                        formControlName="educational_qualification"
                        smsUppercase
                    ></sms-text-input>
                    <sms-text-input
                        label="Occupation (Job/Buisness)"
                        formControlName="occupation"
                        smsUppercase
                    ></sms-text-input>
                    <sms-text-input
                        label="Designation"
                        helpText="Please add your current designation"
                        formControlName="designation"
                        smsUppercase
                    ></sms-text-input>
                    <sms-text-input label="Phone Number" formControlName="phone_number" type="number"></sms-text-input>
                    <sms-text-input
                        label="Alternative Phone Number"
                        formControlName="alternate_phone_number"
                        type="number"
                    ></sms-text-input>
                    <sms-text-input label="Email" formControlName="email"></sms-text-input>
                    <sms-text-input formControlName="annual_income" label="Annual Income" type="number"></sms-text-input
                    ><sms-text-input
                        [mask]="true"
                        label="Aadhar ID"
                        formControlName="aadhaar_number"
                        maskPattern="9999 9999 9999"
                        placeholder="9999 9999 9999"
                    ></sms-text-input>
                    <sms-text-input
                        label="Pan Number"
                        formControlName="pan_number"
                        placeholder="AAAAA9999A"
                        smsUppercase
                    ></sms-text-input>
                </div>
            </sms-accordion-tab>
            <sms-accordion-tab [selected]="true" header="MOTHER'S DETAILS">
                <div class="flex items-start self-stretch gap-4 flex-col" formGroupName="mother">
                    <sms-text-input label="Mother's Name" formControlName="name" smsUppercase></sms-text-input>
                    <sms-text-input
                        label="Education Qualification"
                        formControlName="educational_qualification"
                        smsUppercase
                    ></sms-text-input>
                    <sms-text-input
                        label="Occupation (Job/Buisness)"
                        formControlName="occupation"
                        smsUppercase
                    ></sms-text-input>
                    <sms-text-input
                        label="Designation"
                        helpText="Please add your current designation"
                        formControlName="designation"
                        smsUppercase
                    ></sms-text-input>
                    <sms-text-input label="Phone Number" formControlName="phone_number" type="number"></sms-text-input>
                    <sms-text-input
                        label="Alternative Phone Number"
                        formControlName="alternate_phone_number"
                        type="number"
                    ></sms-text-input>
                    <sms-text-input label="Email" formControlName="email"></sms-text-input>
                    <sms-text-input formControlName="annual_income" label="Annual Income" type="number"></sms-text-input
                    ><sms-text-input
                        [mask]="true"
                        label="Aadhar ID"
                        formControlName="aadhaar_number"
                        maskPattern="9999 9999 9999"
                        placeholder="9999 9999 9999"
                    ></sms-text-input>
                    <sms-text-input
                        label="Pan Number"
                        formControlName="pan_number"
                        placeholder="AAAAA9999A"
                        smsUppercase
                    ></sms-text-input>
                </div>
            </sms-accordion-tab>
            <sms-accordion-tab [selected]="true" header="OTHER DETAILS">
                <div class="flex flex-col items-start self-stretch gap-8" formGroupName="other_detail">
                    <sms-textarea label="Address" formControlName="address" smsUppercase></sms-textarea>
                    <sms-textarea
                        label="Permanent Address"
                        formControlName="permanent_address"
                        smsUppercase
                    ></sms-textarea>
                </div>
            </sms-accordion-tab>
        </p-accordion>
    </div>
    <div class="flex flex-col gap-4 items-start self-stretch my-4">
        <button [loading]="isLoading$ | async" smsButton color="primary" type="submit">
            {{ !preview ? "Save & Next" : "Next" }}
        </button>
        <button (click)="onBack()" smsButton color="primary" variant="outlined" type="button">Back</button>
    </div>
</form>
