<sms-payment-warpper [orderData]="orderData" [redirect]="router.url">
    <div class="flex flex-col gap-3 py-3">
        <sms-alert *ngIf="onlinePaymentEnabled"
            >Note:- GST Amount is calculated on Transaction Fee (Transaction Fee is 2% of Total Amount to be
            Paid)</sms-alert
        >
        <div class="flex justify-between items-center gap-4">
            <div class="font-satoshi text-sm text-gray-800">Amount</div>
            <div class="font-satoshi text-sm text-gray-900 font-medium">&#8377; {{ config.data.amount }}</div>
        </div>
        <ng-container *ngIf="onlinePaymentEnabled">
            <div class="flex justify-between items-center gap-4">
                <div class="font-satoshi text-sm text-gray-800">Transaction Fee (2%)</div>
                <div class="font-satoshi text-sm text-gray-900 font-medium">
                    &#8377; {{ config.data.payment_detail.transaction_fee }}
                </div>
            </div>
            <div class="flex justify-between items-center gap-4">
                <div class="font-satoshi text-sm text-gray-800">GST Amount</div>
                <div class="font-satoshi text-sm text-gray-900 font-medium">
                    &#8377; {{ config.data.payment_detail.gst_amount }}
                </div>
            </div>
        </ng-container>
        <div class="flex justify-between items-center gap-4 border-t border-0 border-dashed border-gray-200">
            <div class="font-satoshi text-sm text-gray-800">Total Amount</div>
            <div class="font-satoshi text-sm text-gray-900 font-medium">
                &#8377; {{ config.data.payment_detail.total_amount }}
            </div>
        </div>
        <div class="flex justify-between items-center gap-4">
            <div class="font-satoshi text-sm text-gray-800">Payment Status</div>
            <div [variant]="!config.data.is_paid ? 'warn' : 'success'" smsChip>
                {{ !config.data.is_paid ? "Due" : "Paid" }}
            </div>
        </div>
        <div class="flex justify-between items-center gap-4">
            <div class="font-satoshi text-sm text-gray-800">Payment Date</div>
            <div class="font-satoshi text-sm text-gray-900 font-medium">
                {{ config.data.payment_date ? (config.data.payment_date | dateTime: dateTimeFormat) : "-" }}
            </div>
        </div>
        <div class="flex justify-between items-center gap-4">
            <div class="font-satoshi text-sm text-gray-800">Paid By</div>
            <div class="font-satoshi text-sm text-gray-900 font-medium">
                {{ config.data?.paid_by?.full_name || "-" }}
            </div>
        </div>
        <div class="flex justify-between items-start gap-2 flex-col" *ngIf="!config.data.paymentMethod">
            <div class="font-satoshi text-sm text-gray-800">Payment Method</div>
            <sms-autocomplete [options]="paymentMethodOptions" [formControl]="paymentMenthod"></sms-autocomplete>
        </div>
    </div>
    <sms-divider [horizontal]="true"></sms-divider>
    <div class="flex justify-end items-center gap-3">
        <button (click)="onClose()" smsButton size="small" type="button" variant="outlined">Close</button>
        <button
            *ngIf="(isAdmin$ | async) && config.data.is_active"
            [loading]="(isLoading$ | async) && clickedButton === 'PayFees'"
            (click)="payFees()"
            smsButton
            size="small"
            type="button"
        >
            Pay Now
        </button>
        <button
            *ngIf="config.data.is_paid"
            [loading]="(isLoading$ | async) && clickedButton === 'UpdateFeesMethod'"
            (click)="updateFeesMethod()"
            smsButton
            size="small"
            type="button"
        >
            Update
        </button>
        <button
            *ngIf="config.data.is_paid"
            [loading]="(isLoading$ | async) && clickedButton === 'DownloadRecipt'"
            (click)="downloadRecipt()"
            smsButton
            size="small"
            type="button"
        >
            Download Recipt
        </button>
    </div>
</sms-payment-warpper>
