import { CommonModule } from "@angular/common";
import { Component, Input, OnDestroy, SimpleChanges } from "@angular/core";
import { RouterLink } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";

import { ButtonDirective } from "src/app/core/directives";
import { FileValidator } from "src/app/core/validators";
import { toFormData } from "src/app/shared/helpers";
import { PostAdmissionStepResponse } from "src/app/shared/interfaces";
import { selectIsMutationLoading } from "src/app/shared/store";

import { AccordionTabComponent, FileInputComponent } from "src/app/shared/components/ui";

import { QuillModule } from "ngx-quill";
import { AccordionModule } from "primeng/accordion";
import { ContentManagementComponentEnum } from "src/app/shared/enum/settings";
import { admissionActions, selectAdmissionData, selectAdmissionIdsData } from "src/app/shared/store";
import {
    contentManagementActions,
    selectContentManagementData,
} from "src/app/shared/store/settings/content-management";

@Component({
    selector: "sms-documents-attached",
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FileInputComponent,
        RouterLink,
        ButtonDirective,
        AccordionTabComponent,
        AccordionModule,
        QuillModule,
    ],
    templateUrl: "./documents-attached.component.html",
    styleUrls: ["./documents-attached.component.scss"],
})
export class DocumentsAttachedComponent implements OnDestroy {
    @Input() preview = false;
    formGroup!: FormGroup;
    admissionIdsData!: PostAdmissionStepResponse | null;

    isLoading$ = this.store.select(selectIsMutationLoading);

    subscription: Subscription = new Subscription();

    contentData: string | undefined = undefined;

    constructor(
        private formBuilder: FormBuilder,
        private store: Store,
    ) {
        this.formGroup = this.formBuilder.group({
            medical_certificate: new FormControl("", [Validators.required, FileValidator.fileMaxSize(150, true)]),
            leaving_certificate: new FormControl("", [FileValidator.fileMaxSize(150, true)]),
            student_photo: new FormControl("", [Validators.required, FileValidator.fileMaxSize(150, true)]),
            mother_photo: new FormControl("", [Validators.required, FileValidator.fileMaxSize(150, true)]),
            father_photo: new FormControl("", [Validators.required, FileValidator.fileMaxSize(150, true)]),
            caste_certificate: new FormControl(""),
            birth_certificate: new FormControl("", [Validators.required, FileValidator.fileMaxSize(150, true)]),
            previous_report_card: new FormControl("", [FileValidator.fileMaxSize(150, true)]),
            udise_of_preschool: new FormControl("", [FileValidator.fileMaxSize(150, true)]),
            aadhaar_image: new FormControl(""),
            student: this.admissionIdsData?.student,
            admission: this.admissionIdsData?.admission,
            is_update: false,
            step: 4,
        });

        this.store.dispatch(
            contentManagementActions.getContentManagementData({
                component: ContentManagementComponentEnum.DOCUMENT_UPLOAD_RULES,
            }),
        );
    }

    ngOnInit(): void {
        scrollTo(0, 0);
        this.subscription.add(
            this.store.select(selectAdmissionIdsData).subscribe((value) => {
                this.admissionIdsData = value;

                this.formGroup.patchValue({
                    ...value,
                });
            }),
        );
        this.getStepData();
        this.store.select(selectContentManagementData).subscribe((data) => {
            this.contentData = data?.content;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["preview"].currentValue) {
            if (this.formGroup) {
                this.formGroup.disable();
            }
        }
    }

    onSave() {
        this.formGroup.markAllAsTouched();
        let fileFields = [
            "medical_certificate",
            "leaving_certificate",
            "student_photo",
            "mother_photo",
            "father_photo",
            "caste_certificate",
            "birth_certificate",
            "previous_report_card",
            "udise_of_preschool",
            "aadhaar_image",
        ];
        if (this.formGroup.valid) {
            this.store.dispatch(
                admissionActions.saveDocumentsAttached({
                    data: toFormData(this.formGroup.getRawValue(), fileFields),
                }),
            );
        }
    }

    onBack() {
        this.store.dispatch(admissionActions.previousStep());
    }

    getStepData() {
        this.store.dispatch(admissionActions.getAdmissionData({ stepNumber: 4 }));
        this.subscription.add(
            this.store.select(selectAdmissionData).subscribe((value) => {
                if (value) {
                    this.formGroup.patchValue({
                        ...value,
                        ...value.other_documents,
                        ...this.admissionIdsData,
                        is_update: true,
                    });
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
