import { createActionGroup, props } from "@ngrx/store";
import {
    AttendancePostInterface,
    CheckInOutTeacherInterface,
    GetAttendanceDataParams,
    GetAttendanceStatusParams,
    TeacherAttendanceInterface,
} from "../../interfaces";

export const attendanceActions = createActionGroup({
    source: "attendance",
    events: {
        "get student attandance date": props<{
            params: GetAttendanceDataParams;
        }>(),

        "set student attandance data": props<{
            data: {
                params: string;
                data: any;
            };
        }>(),
        "mark student attandance": props<{
            data: AttendancePostInterface;
        }>(),
        "mark student attandance date status": props<{
            date: string;
        }>(),
        "get classroom attandance status": props<{
            params: GetAttendanceStatusParams;
        }>(),
        "set classroom attandance status data": props<{
            data: Record<string, boolean>;
        }>(),
        "get student monthly attandance": props<{
            params: GetAttendanceStatusParams;
        }>(),
        "set student monthly attandance": props<{
            data: {
                params: string;
                data: any;
            };
        }>(),
        "check in teacher": props<{ data: CheckInOutTeacherInterface }>(),
        "check out teacher": props<{ data: CheckInOutTeacherInterface }>(),
        "get teachers attendance status": props<{
            params: GetAttendanceStatusParams;
        }>(),
        "set teachers attendance status": props<{
            data: Record<string, TeacherAttendanceInterface>;
        }>(),
        "get teacher attenance for date": props<{ date: string }>(),
        "set teacher attenance for date": props<{ data: { data: TeacherAttendanceInterface; params: string } }>(),
    },
});
