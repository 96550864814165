import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CreateStandardInterface, StandardInterface } from "../../../interfaces";

@Injectable({
    providedIn: "root",
    deps: [HttpClient],
})
export class StandardService {
    constructor(private httpClient: HttpClient) {}

    getAllStandards(): Observable<StandardInterface[]> {
        return this.httpClient.get<StandardInterface[]>(environment.API_URL + "/standards-all/");
    }

    getStandardsForAdmission(academic_year: string): Observable<StandardInterface[]> {
        return this.httpClient.get<StandardInterface[]>(
            environment.API_URL + "/standards-for-admission/?academic_year=" + academic_year,
        );
    }
    // getStandardsForAdmission(academic_year: string): Observable<StandardInterface[]> {
    //     return this.httpClient.get<StandardInterface[]>(environment.API_URL + `/schools/standards-for-admission?academic_year=${academic_year}`);
    // }

    getAllActiveStandards(): Observable<StandardInterface[]> {
        return this.httpClient.get<StandardInterface[]>(environment.API_URL + "/schools/admin/standards/");
    }

    getAllInActiveStandards(): Observable<StandardInterface[]> {
        return this.httpClient.get<StandardInterface[]>(environment.API_URL + "/standards-inactive/");
    }

    getStandardsForFees(): Observable<StandardInterface[]> {
        return this.httpClient.get<StandardInterface[]>(environment.API_URL + "/schools/admin/standards-for-fees/");
    }

    create(data: CreateStandardInterface): Observable<CreateStandardInterface> {
        return this.httpClient.post<CreateStandardInterface>(
            environment.API_URL + "/schools/admin/standard-config/create/",
            data,
        );
    }

    update(data: CreateStandardInterface, id: string): Observable<CreateStandardInterface> {
        return this.httpClient.put<CreateStandardInterface>(
            environment.API_URL + `/schools/admin/standard-config/${id}/`,
            data,
        );
    }
}
