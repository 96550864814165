import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
    AttendancePostInterface,
    CheckInOutTeacherInterface,
    GetAttendanceDataParams,
    GetAttendanceStatusParams,
    TeacherAttendanceInterface,
} from "../../interfaces";

@Injectable({
    providedIn: "root",
})
export class AttendanceService {
    constructor(private httpClient: HttpClient) {}

    getStudentAttandanceDate(params: GetAttendanceDataParams): Observable<any> {
        return this.httpClient.get<any>(environment.API_URL + "/app/student-attendance/", {
            params: params as Params,
        });
    }

    getAttendanceStatus(params: GetAttendanceStatusParams): Observable<any> {
        return this.httpClient.get<any>(environment.API_URL + "/app/reports/classroom-monthly-attendance/", {
            params: params as Params,
        });
    }

    getStudentAttendanceStatus(params: GetAttendanceStatusParams): Observable<any> {
        return this.httpClient.get<any>(environment.API_URL + "/app/reports/student-monthly-attendance/", {
            params: params as Params,
        });
    }

    markAttandance(data: AttendancePostInterface): Observable<AttendancePostInterface> {
        return this.httpClient.post<AttendancePostInterface>(environment.API_URL + "/app/student-attendance/", data);
    }

    checkInTeacher(data: CheckInOutTeacherInterface): Observable<{ data: TeacherAttendanceInterface }> {
        return this.httpClient.post<{ data: TeacherAttendanceInterface }>(
            environment.API_URL + "/app/teacher-attendance/",
            data,
        );
    }

    checkOutTeacher(data: CheckInOutTeacherInterface): Observable<{ data: TeacherAttendanceInterface }> {
        return this.httpClient.put<{ data: TeacherAttendanceInterface }>(
            environment.API_URL + "/app/teacher-attendance/",
            data,
        );
    }

    getTeacherAttenanceForDate(date: string): Observable<TeacherAttendanceInterface> {
        return this.httpClient.get<TeacherAttendanceInterface>(environment.API_URL + "/app/teacher-attendance/", {
            params: { date },
        });
    }

    getTeachersAttendanceStatus(params: GetAttendanceStatusParams): Observable<any> {
        return this.httpClient.get<any>(environment.API_URL + "/app/get-monthly-attendance/", {
            params: params as Params,
        });
    }
}
