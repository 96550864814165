import { createFeature, createReducer, on } from "@ngrx/store";
import { StudentStoreInterface } from "src/app/shared/interfaces";
import { v4 as uuidv4 } from "uuid";
import { studentActions } from "./student.action";

const initailState: StudentStoreInterface = {
    students: [],
    studentCount: 0,
    stepCount: 1,
    student: null,
    studentImportData: null,
    studentLcData: null,
};

const studentFeature = createFeature({
    name: "student",
    reducer: createReducer(
        initailState,
        on(studentActions.setStudents, (state, action) => ({
            ...state,
            students: action.data.rows,
            studentCount: action.data.count,
        })),
        on(studentActions.setStepCount, (state, action) => ({
            ...state,
            stepCount: action.count,
            studentImportData: action.count === 1 ? null : state.studentImportData,
        })),
        on(studentActions.onUploadStudentsSuccess, (state, action) => ({
            ...state,
            studentImportData: action.data.map((item) => ({ ...item, id: uuidv4() })),
        })),
        on(studentActions.removeStudentOfImport, (state, action) => {
            const data = state.studentImportData
                ? state.studentImportData.filter((item) => action.data.includes(item.id))
                : null;
            return {
                ...state,
                studentImportData: data,
                stepCount: data?.length ? state.stepCount : state.stepCount - 1,
            };
        }),
        on(studentActions.setStudentData, (state, action) => ({
            ...state,
            student: action.data,
        })),
        on(studentActions.setStudentLcData, (state, action) => ({
            ...state,
            studentLcData: action.data,
        })),
        on(studentActions.clearBulkUploadState, (state, action) => ({
            ...state,
            stepCount: 1,
            studentImportData: null,
        })),
    ),
});

export const {
    name: studentFeatureKey,
    reducer: studentReducer,
    selectStudents,
    selectStudentCount,
    selectStepCount,
    selectStudentImportData,
    selectStudent,
    selectStudentLcData,
} = studentFeature;
