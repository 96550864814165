<ng-container *ngIf="control.disabled; else elseTemplate">
  <div class="flex flex-col gap-1">
    <sms-label>{{ label }}</sms-label>
    <p class="text-base font-satoshi text-gray-900 px-1">
      {{ control.getRawValue() ? (control.getRawValue() | dateTime) : "-" }}
    </p>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <span class="p-float-label">
    <p-calendar
      class="!w-full block"
      id="{{ label }}"
      [inputStyleClass]="isError() ? 'invalid' : ''"
      [formControl]="control"
      [dateFormat]="dateFormat"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [selectionMode]="selectionMode"
      [showIcon]="true"
      [showTime]="showTime"
      [showButtonBar]="showButtonBar"
      [showSeconds]="showSeconds"
      [panelStyleClass]="hideHeader ? 'hideHeader' : ''"
      [showOtherMonths]="showOtherMonths"
      [timeOnly]="timeOnly"
      [view]="view"
      appendTo="body"
      [hourFormat]="timeFormat"
    ></p-calendar>
    <sms-label *ngIf="label" [required]="isRequired">{{ label }}</sms-label>
    <sms-display-validation-error
      [control]="control"
      [label]="label"
      *ngIf="isError()"
    ></sms-display-validation-error>
  </span>
</ng-template>
