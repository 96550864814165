import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { AcademicYearInterface, TeacherInterface } from "src/app/shared/interfaces";
import {
    AdminDataInterface,
    AdminLoginInterface,
    ForgotPasswordOTPResponseInterface,
    RequestForgotPasswordOTPInterface,
    StudentDataInterface,
    StudentLoginInterface,
    TeacherLoginInterface,
    UpdatePasswordInterface,
    VerifyOTPInterface,
} from "../../interfaces";

export const authActions = createActionGroup({
    source: "auth",
    events: {
        // student auth actions
        "student login": props<{ data: StudentLoginInterface }>(),
        "student login success": props<{ data: StudentDataInterface }>(),
        "student logout": emptyProps(),
        "set id card filled": emptyProps(),
        "set document added": emptyProps(),
        "set users data": props<{ data: StudentDataInterface[] }>(),
        "set user type": props<{ data: string }>(),
        "set current user": props<{ data: StudentDataInterface }>(),
        "remove user": props<{ data: StudentDataInterface | null }>(),
        // teacher auth actions
        "teacher login": props<{ data: TeacherLoginInterface }>(),
        "teacher login success": props<{ data: TeacherInterface }>(),
        "teacher logout": emptyProps(),
        // admin auth actions
        "admin login": props<{ data: AdminLoginInterface }>(),
        "admin login success": props<{ data: AdminDataInterface }>(),
        "admin logout": emptyProps(),
        //forgot password actions
        "request forgot password OTP": props<{ data: RequestForgotPasswordOTPInterface }>(),
        "verify OTP": props<{ data: VerifyOTPInterface }>(),
        "update password": props<{ data: UpdatePasswordInterface }>(),
        "set forgot password step": props<{ setpNumber: number }>(),
        "set is OTP valid": props<{ data: boolean }>(),
        "set forgot password data": props<{ data: ForgotPasswordOTPResponseInterface | null }>(),
        "update active academic year": props<{ data: AcademicYearInterface }>(),
    },
});
