export const environment = {
    serviceWorker: false,
    production: false,
    DEFAULT_CONFIG_NAME: "default",
    SCHOOL_CONFIG_NAME: "default",
    RAZORPAY_KEY_ID: "rzp_test_mQwJMUtpUIuXi7",
    FRONTEND_URL: "https://sms-demo.quartoloom.com",
    API_URL: "https://sms-demo.quartoloom.com:8000/api",
    MEDIA_FOLDER_URL: "https://sms-demo.quartoloom.com:8000",
    AVATAR_GENRATION_API: "https://ui-avatars.com/api",
};
