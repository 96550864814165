import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, map, of, switchMap } from 'rxjs';
import { appEventActions } from '../../app-event';
import { contentManagementActions } from './content-management.action';
import { ContentManagementService } from 'src/app/shared/services/settings/content-management/content-management.service';

@Injectable()
export class contentManagementEffects {
  constructor(
    private actions$: Actions,
    private contentManagementService: ContentManagementService
  ) {}

  getContentManagementList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contentManagementActions.getContentManagementData),
      switchMap((data) =>
        this.contentManagementService
          .getSingleContentManagementByComponentName(data.component)
          .pipe(
            map((data) => {
              return contentManagementActions.setContentManagementData({
                data,
              });
            })
          )
      )
    )
  );

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contentManagementActions.create),
      switchMap((data) =>
        this.contentManagementService.create(data.data).pipe(
          switchMap(() => {
            data.onClose(true);
            return of(
              appEventActions.addToast({
                severity: 'success',
                detail: 'Content added successfully.',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    );
  });
  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contentManagementActions.update),
      switchMap((data) =>
        this.contentManagementService.update(data.data, data.id).pipe(
          switchMap(() => {
            data.onClose(true);
            return of(
              appEventActions.addToast({
                severity: 'success',
                detail: 'Content updated successfully.',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    );
  });

  generateDefaultContent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contentManagementActions.generateDefaultContent),
      switchMap((data) =>
        this.contentManagementService.generateDefaultContent().pipe(
          switchMap(() => {
            data.onClose(true);
            return of(
              appEventActions.addToast({
                severity: 'success',
                detail: 'Content generated successfully.',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    );
  });
}
