<button
    [variant]="variant"
    [size]="size"
    [disabled]="control.disabled"
    [loading]="!!loading"
    (click)="openFileInput()"
    smsButton
>
    <input
        #inputField
        [accept]="accept"
        [multiple]="multiple"
        [disabled]="control.disabled"
        (change)="onChange($event)"
        hidden
        type="file"
    />
    <ng-container
        *ngIf="control && (multiple ? control.getRawValue().length : control.getRawValue()); else elseTemplate"
    >
        {{ multiple ? control.getRawValue().length : 1 }} file selected
    </ng-container>
    <ng-template #elseTemplate>
        <ng-content></ng-content>
    </ng-template>
</button>
