<form [formGroup]="formGroup" (ngSubmit)="onSave()">
    <div class="mt-6 gap-5 grid sm:grid-cols-1 w-full">
        <sms-text-input
            [mask]="true"
            label="Phone Number"
            formControlName="phone"
            maskPattern="9999999999"
        ></sms-text-input>
        <sms-autocomplete
            [options]="academicYears || []"
            label="Academic Year"
            field="name"
            formControlName="academic_year"
        ></sms-autocomplete>
        <div class="flex justify-between items-center gap-3 w-full">
            <sms-autocomplete
                [options]="standards$ | async"
                label="Select Class"
                field="label"
                formControlName="standard"
            ></sms-autocomplete>
        </div>
        <div class="flex justify-between items-center gap-3 w-full">
            <sms-autocomplete
                [options]="formGroup.get('standard')!.value ? formGroup.get('standard')!.value.batches : []"
                label="Select Batch"
                field="label"
                formControlName="batch"
            ></sms-autocomplete>
        </div>
        <sms-text-input
            [mask]="true"
            [required]="formGroup.get('standard')!.value && formGroup.get('standard')!.value.is_aadhaar_required"
            [helpText]="
                formGroup.get('standard')!.value && !formGroup.get('standard')!.value.is_aadhaar_required
                    ? aadharHelpText
                    : null
            "
            label="Aadhar ID"
            formControlName="aadhaar_number"
            maskPattern="9999 9999 9999"
            placeholder="9999 9999 9999"
        ></sms-text-input>

        <sms-text-input
            label="First Name"
            formControlName="first_name"
            helpText="First Name should be as per Aadhar."
            smsUppercase
        ></sms-text-input>
        <sms-text-input
            label="Middle Name"
            formControlName="middle_name"
            helpText="Middle Name should be as per Aadhar."
            smsUppercase
        ></sms-text-input>
        <sms-text-input
            label="Last Name"
            formControlName="last_name"
            helpText="Last Name should be as per Aadhar."
            smsUppercase
        ></sms-text-input>
        <sms-text-input
            label="Name as per Aadhar"
            formControlName="name_as_aadhar"
            helpText="Full Name should be as per Aadhar."
            smsUppercase
        ></sms-text-input>
        <sms-radio-button [options]="gender" label="Gender" formControlName="gender"></sms-radio-button>

        <sms-date-time label="Date of Birth" formControlName="dob"></sms-date-time>
        <sms-text-input smsUppercase label="Nationality" formControlName="nationality"></sms-text-input>
        <sms-text-input label="Place of Birth" formControlName="place_of_birth" smsUppercase></sms-text-input>
        <sms-autocomplete
            [options]="motherTongueData || []"
            label="Mother Tongue"
            formControlName="mother_tongue"
        ></sms-autocomplete>
        <sms-radio-button
            [yesNo]="true"
            label="Transport Facility"
            formControlName="transport_facility_required"
        ></sms-radio-button>
        <sms-autocomplete
            [options]="casteData || []"
            label="Select Caste"
            field="label"
            formControlName="caste"
        ></sms-autocomplete>
        <sms-autocomplete
            [options]="this.formGroup!.get('caste')?.value ? this.formGroup!.get('caste')?.value.sub_castes : []"
            label="Sub Caste"
            field="label"
            formControlName="sub_caste"
        ></sms-autocomplete>
        <sms-autocomplete
            [options]="bloodGroupOptions"
            label="Blood Group"
            formControlName="blood_group"
        ></sms-autocomplete>
    </div>
    <div class="flex flex-col gap-4 items-start self-stretch my-4">
        <button [loading]="isLoading$ | async" smsButton color="primary" type="submit">
            {{ !preview ? "Save & Next" : "Next" }}
        </button>
    </div>
</form>
