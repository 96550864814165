import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[smsIconButton]',
  standalone: true,
})
export class IconButtonDirective {
  @HostBinding('class')
  buttonClassess: string =
    'text-xl text-primary cursor-pointer hover:bg-gray-200 rounded-full p-2 w-11 h-11 flex justify-center items-center';

  constructor() {}
}
