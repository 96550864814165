import { CommonModule } from "@angular/common";
import { Store } from "@ngrx/store";
import { AccordionModule } from "primeng/accordion";
import { Subscription } from "rxjs";

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";

import { ButtonDirective } from "src/app/core/directives";
import { UppercaseDirective } from "src/app/shared/directive";

import { AccordionTabComponent, TextInputComponent, TextareaComponent } from "src/app/shared/components/ui";

import {
    admissionActions,
    selectAdmissionData,
    selectAdmissionIdsData,
    selectIsMutationLoading,
    studentActions,
} from "src/app/shared/store";

import { PostAdmissionStepResponse, PostParentsInfoInterface } from "src/app/shared/interfaces";

@Component({
    selector: "sms-parents-info",
    standalone: true,
    imports: [
        CommonModule,
        TextInputComponent,
        TextareaComponent,
        ReactiveFormsModule,
        AccordionModule,
        AccordionTabComponent,
        ButtonDirective,
        UppercaseDirective,
    ],
    templateUrl: "./parents-info.component.html",
    styleUrls: ["./parents-info.component.scss"],
})
export class ParentsInfoComponent implements OnInit, OnChanges, OnDestroy {
    @Input() preview = false;
    formGroup!: FormGroup;
    admissionIdsData: PostAdmissionStepResponse | null = null;
    isLoading$ = this.store.select(selectIsMutationLoading);

    subscription: Subscription = new Subscription();
    constructor(
        private formBuilder: FormBuilder,
        private store: Store,
    ) {
        this.formGroup = this.formBuilder.group({
            father: this.formBuilder.group({
                name: new FormControl("", Validators.required),
                educational_qualification: new FormControl("", Validators.required),
                occupation: new FormControl("", Validators.required),
                phone_number: new FormControl("", Validators.required),
                alternate_phone_number: new FormControl(""),
                designation: new FormControl("", Validators.required),
                email: new FormControl("", Validators.required),
                annual_income: new FormControl("", Validators.required),
                aadhaar_number: new FormControl(""),
                pan_number: new FormControl(""),
            }),
            mother: this.formBuilder.group({
                name: new FormControl("", Validators.required),
                educational_qualification: new FormControl("", Validators.required),
                occupation: new FormControl("", Validators.required),
                designation: new FormControl("", Validators.required),
                phone_number: new FormControl("", Validators.required),
                alternate_phone_number: new FormControl(""),
                email: new FormControl("", Validators.required),
                annual_income: new FormControl("", Validators.required),
                aadhaar_number: new FormControl(""),
                pan_number: new FormControl(""),
            }),
            other_detail: this.formBuilder.group({
                address: new FormControl("", Validators.required),
                permanent_address: new FormControl("", Validators.required),
            }),
            is_update: false,
        });
    }

    ngOnInit(): void {
        scrollTo(0, 0);
        this.store.dispatch(studentActions.loadStudents());
        this.subscription.add(
            this.store.select(selectAdmissionIdsData).subscribe((value) => (this.admissionIdsData = value)),
        );

        this.getStepData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["preview"].currentValue) {
            if (this.formGroup) {
                this.formGroup.disable();
            }
        }
    }

    onSave() {
        if (this.preview) {
            this.store.dispatch(admissionActions.nextStep());
            return;
        }

        this.formGroup.markAllAsTouched();
        if (this.formGroup.valid) {
            const data: PostParentsInfoInterface = {
                step: 2,
                admission: this.admissionIdsData!.admission,
                student: this.admissionIdsData!.student,
                ...this.formGroup.getRawValue(),
            };
            this.store.dispatch(admissionActions.saveParentsInfo({ data }));
        }
    }

    onBack() {
        this.store.dispatch(admissionActions.previousStep());
    }

    getStepData() {
        this.store.dispatch(admissionActions.getAdmissionData({ stepNumber: 2 }));
        this.subscription.add(
            this.store.select(selectAdmissionData).subscribe((value) => {
                if (value) {
                    this.formGroup.patchValue({
                        ...value,
                        is_update: true,
                    });
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
