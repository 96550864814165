import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Observable, debounceTime, distinctUntilChanged } from "rxjs";
import { authActions } from "src/app/core/stores";
import { ACTIVE_ACADEMIC_YEAR } from "src/app/shared/constants";
import { AcademicYearInterface, ResponseInterface } from "src/app/shared/interfaces";
import { LocalStorageService } from "src/app/shared/services";
import { lovsActions, selectLovData } from "src/app/shared/store";
import { AutocompleteComponent } from "../../ui/inputs/autocomplete/autocomplete.component";

@Component({
    selector: "sms-switch-academic-year",
    standalone: true,
    imports: [AutocompleteComponent, CommonModule, ReactiveFormsModule],
    templateUrl: "./switch-academic-year.component.html",
    styleUrls: ["./switch-academic-year.component.scss"],
})
export class SwitchAcademicYearComponent implements OnInit {
    academicYearDataKey = "/schools/admin/academic-year/list/";
    academicYearData$: Observable<ResponseInterface<AcademicYearInterface[]>> = this.store.select(
        selectLovData({ url: "/schools/admin/academic-year/list/" }),
    );
    academicYearInLocalStorage: AcademicYearInterface = this.localStorageService.getItem(ACTIVE_ACADEMIC_YEAR);
    selectedAcademicYear: FormControl = new FormControl("", [Validators.required]);

    constructor(
        private store: Store,
        private localStorageService: LocalStorageService,
    ) {}

    ngOnInit(): void {
        this.store.dispatch(lovsActions.getLovData({ data: { url: this.academicYearDataKey } }));
        this.selectedAcademicYear.setValue(this.academicYearInLocalStorage || "");
        this.selectedAcademicYear.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
            if (value.id !== this.academicYearInLocalStorage.id) {
                this.academicYearInLocalStorage = value;
                this.store.dispatch(authActions.updateActiveAcademicYear({ data: value }));
            }
        });
    }
}
