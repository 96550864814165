import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AgreeDeclarationInterface,
  PostAdmissionStepResponse,
  PostChildrenInfoInterface,
  PostParentsInfoInterface,
  PostSupplementryInfoInterface,
} from 'src/app/shared/interfaces';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { GetAdmissionService } from './get-admission.service';

@Injectable({
  providedIn: 'root',
})
export class SaveAdmsisionService extends GetAdmissionService {
  constructor(protected override httpClient: HttpClient) {
    super(httpClient);
  }

  saveAdmissionData(
    data:
      | PostChildrenInfoInterface
      | PostParentsInfoInterface
      | PostSupplementryInfoInterface
      | AgreeDeclarationInterface
  ): Observable<PostAdmissionStepResponse & { form_fees_id: string }> {
    return this.httpClient.post<
      PostAdmissionStepResponse & { form_fees_id: string }
    >(environment.API_URL + '/admission/', data);
  }

  saveDocumentAttached(data: FormData): Observable<PostAdmissionStepResponse> {
    return this.httpClient.post<PostAdmissionStepResponse>(
      environment.API_URL + '/admission-documents-upload/',
      data
    );
  }

  payInstalment(data: { ins_id: string; payment_method: string }) {
    return this.httpClient.post<{ admission: string }>(
      environment.API_URL + '/schools/admin/admission-fees-instalment/',
      data
    );
  }

  payOtherCharges(data: any) {
    return this.httpClient.post<{ admission: string }>(
      `${environment.API_URL}/schools/admission-fees-pay/${data.id}/`,
      data
    );
  }

  updatePaymentMethod(data: any) {
    return this.httpClient.put<{ admission: string }>(
      `${environment.API_URL}/schools/admin/update-payment-method/`,
      data
    );
  }
}
